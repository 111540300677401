.wrapper-border {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  margin: -2rem !important;
  color: rgb(74 85 104 / var(--tw-text-opacity));
}

.active {
  border-color: #013a6a !important;
  border-bottom-width: 2px !important;
}