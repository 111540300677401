.ql-container {
  font-size: 10pt;
}

.hide-toolbar .ql-toolbar {
    display: none;
    border-style: none;
}

.hide-toolbar .ql-container {
    border-style: none;
}

.ql-picker.ql-font .ql-picker-label[data-value="interSystemUi"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="interSystemUi"]::before
{
    font-family: "Inter", system-ui;
    content: "Inter";
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;
    content: "Raleway";
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before
{
    font-family: "Arial", sans-serif;
    content: "Arial";
}

.ql-picker.ql-font .ql-picker-label[data-value="times"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="times"]::before
{
    font-family: "Times New Roman", Times, serif;
    content: "Times New Roman";
}

/* Set content font-families */
.ql-font-interSystemUi {
    font-family: "Inter", system-ui;
  }
.ql-font-times {
  font-family: "Times New Roman", Times, serif;
}
.ql-container.ql-font-Raleway {
  font-family: "Raleway"
}
/* Set content font-families */
.ql-font-Arial {
  font-family: "Arial";
}

/* Set font sizes */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8pt"]::before {
  content: '8';
  font-size: 8pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '10';
  font-size: 10pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12pt"]::before {
  content: '12';
  font-size: 12pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14pt"]::before {
  content: '14';
  font-size: 14pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16pt"]::before {
  content: '16';
  font-size: 16pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18pt"]::before {
  content: '18';
  font-size: 18pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20pt"]::before {
  content: '20';
  font-size: 20pt;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32pt"]::before {
  content: '32';
  font-size: 32pt;
}
