.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  text-align: center;
  justify-content: center;
}

.container {
  margin: -2rem !important;
}

.containerNavButtons {
  margin-right: calc(-2rem + 10px) !important;
  margin-left: calc(-2rem + 10px) !important;
  margin-top: calc(2rem + 2vh) !important;
  margin-bottom: 8vh !important;
}

/* See https://tailwindcss.com/docs/customizing-colors for colors */

.yellow {
  border-color: #000 !important;
  background-color: rgba(202, 138, 4, .6) !important;
}

.yellow:hover {
  cursor: pointer;
  background-color: rgba(202, 138, 4, .8) !important;
}

.green {
  border-color: #000 !important;
  background-color: rgba(22, 163, 74, .6) !important;
}

.green:hover {
  cursor: pointer;
  background-color: rgba(22, 163, 74, .8) !important;
}

.blue {
  border-color: #000 !important;
  background-color: rgba(3, 105, 161, .6) !important;
}

.blue:hover {
  cursor: pointer;
  background-color: rgba(3, 105, 161, .8) !important;
}

.nav-button-text {
  color: white;
}

.nav-button-background {
  height: 100%;
}


.brightest-text{
  color: rgba(96, 165, 250, .6) !important;
}

.bright-text{
  color: rgba(96, 165, 250, .4) !important;
}

.mutual-text{
  color: rgba(96, 165, 250, .3) !important;
}

.dark-text{
  color: rgba(96, 165, 250, .2) !important;
}

.darkest-text{
  color: rgba(96, 165, 250, .05) !important;
}
